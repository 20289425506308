define("discourse/plugins/discourse-topic-noindex/discourse/initializers/add-noindex-item-to-topic-wrench-menu", ["exports", "discourse/lib/ajax", "discourse/lib/plugin-api"], function (_exports, _ajax, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line no-unused-vars
  const PLUGIN_ID = "discourse-topic-noindex";
  var _default = _exports.default = {
    name: "discourse-topic-noindex",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", initialize);
    }
  };
  function initialize(api) {
    api.addTopicAdminMenuButton(topic => {
      const canManageTopic = api.getCurrentUser()?.canManageTopic;
      const noindex = topic.get("noindex");
      if (!topic.isPrivateMessage && canManageTopic) {
        return {
          icon: noindex ? "far-eye" : "far-eye-slash",
          label: noindex ? "topic.actions.noindex_stop" : "topic.actions.noindex",
          action: () => {
            (0, _ajax.ajax)(`/t/${topic.id}/toggle-noindex`, {
              type: "PUT"
            }).then(() => {
              topic.reload();
            });
          }
        };
      }
    });
  }
});